.cartButton {
  content: attr(value);
  font-size: 12px;
  background: red;
  color: #fff;
  border-radius: 50px;
  padding: 0 5px;
  position: relative;
  left: -8px;
  top: -10px;
  opacity: 0.9;
}

/* Styling for the button */
.toggle-btn {
  padding: 5px 5px;
  font-size: 12px;
  border: 3px solid #6e6c6c;
  border-radius: 20px;
  background-color: #fff6f6;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* When the toggle is ON */
.toggle-btn.on {
  background-color: #7fad39;
  /* Green for ON */
  color: white;
}

/* When the toggle is OFF */
.toggle-btn.off {
  background-color: rgb(232, 48, 48);
  /* Grey for OFF */
  color: #333;
}

p {
  font-size: 16px;
  /* font-weight: bold; */
}

.vendor__logo {
  border-radius: 5px;
  height: 65px;
  margin-right: 10px;
}

.vendor__logo_blank {
  padding: 24px 0;
}

@media (max-width: 768px) {
  .vendor_detail {
    padding-top: 2px !important;

  }
}
@media (max-width: 768px) {
  .vendor_detail_logo a{
    display: block;
  }
}
.headerStyle {
  background: rgb(4, 136, 4);
}

.vendor_name {
  font-size: 20px;
  font-weight: 700;
}

.vendor_detail {
  padding-top:52px;
}

.logo_cover{
  margin-top: 8px;
}

.header-fix {
  position: sticky;
  top: 0;
  background: #5e895e;
  z-index: 99;
  box-shadow:0px 1px 1px 0px lightgray;
}


@media(max-width:767px){

.humberger__menu__logo img {
  width: 50px;
}
.header__top__right__social ul li a {
  padding: 10px 0;
  border-bottom: 1px solid #eee;
  display: block;
}
.humberger__menu__widget {
  margin-bottom: 0px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}
}

.humberger__menu__logo{

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}



/* General styling for the container */
.search-container {
  position: relative;
}

/* Search Icon Button */
.search-icon-btn {
  background-color: rgb(4, 136, 4);
  border: none;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 2; /* Ensure the icon is above the tab */
}

/* Search Tab Styling */
.search-tab {
  position: fixed;
  top: 12.5%; /* Center the tab vertically */
  left: 60%; /* Center the tab horizontally */
  transform: translate(-50%, -50%); /* Adjust for true centering */
  background-color: #f9f9f9;
  width: 35%; /* Adjust width to your needs */
  padding: 0;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  transition: width 0.3s ease-out, padding 0.3s ease-out;
}

@media (max-width: 768px) {
  .search-tab {
    position: fixed;
    top: 13%; /* Center the tab vertically */
    left: 50%; /* Center the tab horizontally */
    transform: translate(-50%, -50%); /* Adjust for true centering */
    background-color: #f9f9f9;
    width: 80%; /* Adjust width to your needs */
    padding: 0;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
    transition: width 0.3s ease-out, padding 0.3s ease-out;
  }

}
/* Search input field styling */
.search-tab input {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: width 0.3s ease-out;
}

.search-tab input:focus {
  border-color: #4CAF50;
}

/* Active state when the search tab is open */
.search-tab.open {
  width: 200px;  /* Adjust the width for the search tab */
  padding: 10px;
}
.price_property{
  color: #fff !important;
}

.cart-item-count {
  color: white;
  border-radius: 50%;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: bold;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 20px;
  min-height: 20px;
}

.cart-item-count {
  width: auto;  /* Auto width to fit larger numbers */
  padding: 5px 10px;  /* Adjust padding for larger numbers */
}
