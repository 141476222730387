/* FinanceDashboard.css */
.wallet-container {
    width: 50%;
    margin-bottom: 30px;
}

.card-container {
    border-radius: 12px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: #def7de;
}

.text-center h2 {
    font-weight: bold;
}

.balance-button {
    border-radius: 8px;
    width: 25%;
    margin-top: 10px;

}

.row .col-6 .card {
    background: #f8f9fa;
    text-align: center;
}

.transactions-card {
    display: flex;
    align-items: center;
    padding: 12px;
    border-radius: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.transactions-card img {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.text-danger {
    color: red !important;
}

.text-success {
    color: green !important;
}

@media only screen and (max-width: 768px) {
    .wallet-container {
        width: 100%;
        margin-bottom: 50px;
    }

    .card-container {
        border-radius: 12px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
    }

    .text-center h2 {
        font-weight: bold;
    }

    .balance-button {
        border-radius: 8px;
        width: 80%;
    }

    .row .col-6 .card-container {
        background: #f8f9fa;
        text-align: center;
    }

    .transactions-card {
        display: flex;
        align-items: center;
        padding: 12px;
        border-radius: 10px;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    }

    .transactions-card img {
        border-radius: 50%;
        width: 40px;
        height: 40px;
        margin-right: 10px;
    }

    .text-danger {
        color: red !important;
    }

    .text-success {
        color: green !important;
    }
}
