.catbutton{
    /* color:white;
    background: #b4bdc3;
    color: white;
    margin: 5px;
    padding: 6px;
    box-shadow: inset 3px 6px 5px 0px; */
    cursor: pointer;
}

.catbutton:active{
    box-shadow: none;
}

.catactive{
    color: white ;
    background: rgb(4, 136, 4);
    /* margin: 5px; */
    padding: 0px 7px;
    cursor: pointer;
    border-radius: 8px;
}

    .mcat{
        display: none;
    }

    .dcat{
      display: block;
    }


    .offer-zone{
        margin-bottom: 35px;
    }

    @media screen and  (max-width: 576px) {
       .mcat{
        display: block;
       }
       .dcat{
        display: none;
       }

       .product__item__pic {
        height: 120px !important;
        position: relative;
        overflow: hidden;
        background-position: center;
        background-repeat: no-repeat;
    }
    }

    .sidebar{
        text-align: center;
        padding: 1px 8px;
        /* border-right: 1px solid lightgray; */
        /* height: 80vh; */
        overflow-y: auto;
        /* position: sticky; */
        top: 145px;
    }

    .header__top__right__social button.toggle-btn.on {
        padding: 8px 10px;
        border: 0;
        font-size: 14px;
    }
    span.vendor_name {
        color: #fff;
        margin-left: -14px;
    }
    .header__top__left a {
        color: #000;
    }

    /* search bar css start here */
    .search_box button.btn{
        background: #3d7a81;
        color: #fff;
    }
    .search_box {
        display: flex;
        margin: 10px 0 20px;
    }
    .search_box input.form-control {
        height: 48px;
        border: 1px solid #ddd;
        border-radius: 0;
    }
    .search_box button.btn {
        border-radius: 0;
        width: 20%;
    }
    
    .slider_wrapper {
        margin: 20px 0px;
    }
    .category_box a {
        display: block;
        position: relative;
    }
 
 
.category_box a h5 {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    text-align: center;
    background: #FFF;
    padding: 10px 0;
    max-width: 90%;
    margin: 0 auto;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 5px;
}

.category_box img{
    width: 100%;
}
    .slider_wrapper .swiper-button-prev, .slider_wrapper .swiper-button-next {
        color: #000;
        font-size: 20px;
        border: 1px solid #ccc;
        padding: 20px 10px;
        background: #fff;
    }
    .slider_wrapper .swiper-button-prev:after, .slider_wrapper .swiper-button-next:after  {
        font-size: 18px;
    }
    .featured_box_heading{
        position: relative;
        text-align: center;
    }

    .featured_box_heading h3:after {
        position: absolute;
        left: 0;
        bottom: -15px;
        right: 0;
        height: 4px;
        width: 80px;
        background: #7fad39;
        content: "";
        margin: 0 auto;
    }
    .featured_tab ul {
        justify-content: center;
        margin-top: 50px;
        padding-bottom: 10px;
    }
    .featured_tab ul li.nav-item button {
        background: unset;
        border: unset;
    }
    .featured_tab ul li button.nav-link.active {
        color: #7fad39;
    }

    .featured_tab ul li button.nav-link{
        font-weight: bold;
    }
    .product_list_item {
        display: grid    ;
        grid-template-columns: repeat(4, 1fr);
        justify-content: space-between;
        gap: 30px;
        padding: 20px;
    }
    .product_item_image img {
        width: 100%;
    }
    .product_list_item .product__item {
        border: 1px solid #eee;
        margin-bottom: 20px;
    }
    .sidebar__item ul li a {
        border-bottom: 1px solid #eee;
        text-align: left;
        padding: 0 20px;
    }

    .sidebar__item ul li a.catactive{
        border-radius: 0;
        color: #fff;
    }
    .sidebar__item h4 {
        text-align: left;
        font-size: 20px;
        margin-top: 10px;
        color: #3d7a81;
        margin-left: 10px;
    }
    
    @media(max-width:767px){
        .product_list_item {
            display: grid    ;
            grid-template-columns: repeat(2, 1fr);     
            gap: 30px;
            padding: unset;
        }
        .product__item__text h6 {
            margin-bottom: 10px;
            font-size: 12px;
        }
    }
    @media only screen and (max-width: 768px) {
        .category-section{
            margin-bottom: 70px;
        }
    
    }


     /* banner size fixed */
  /* Ensure the Swiper takes up full width and height */
.swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
   
  }
  
  /* Stretch the image to fill the container */
  .banner-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* This makes sure the image stretches to fill the container without distorting */
    /* background-color: #7fad39; */
    border-radius: 15px;
  }
  
  .banner-slide {
    position: relative;
    width: 100%;
    height: 300px; /* Adjust to desired height */
  }
  
  @media only screen and (max-width: 768px) {
    .swiper {
        width: 100%;
        height: 100%;
      }
      
      .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
       
      }
      
      /* Stretch the image to fill the container */
      .banner-image {
        width: 100%;
        height: 100%;
        object-fit: cover; /* This makes sure the image stretches to fill the container without distorting */
        /* background-color: #7fad39; */
        border-radius: 15px;
      }
      
      .banner-slide {   
        position: relative;
        width: 100%;
        height: 200px; /* Adjust to desired height */
      }
      
    }


    /* search bar css end here */
    