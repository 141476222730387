/* OrderDetails.css */

/* Basic Styles */
.order-details {
  padding: 20px;
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.order-details h2 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

/* Product List Styles */
.order-details ul {
  list-style-type: none;
  padding: 0;
}

.product-item {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  margin-bottom: 15px;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.product-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

/* Product Image */
.product-image img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 20px;
  border-radius: 8px;
}

/* Product Info */
.product-info {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.product-name {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.product-price,
.product-quantity,
.product-total {
  font-size: 14px;
  color: #666;
  margin-bottom: 5px;
}

/* Total Price Section */
.order-total {
  font-size: 14px;
  /* font-weight:500; */
  text-align: right;
  margin-top: 10px;
  color: #333;
}

/* Download Button Styles (for mobile) */
.download-button {
  display: inline-block;
  padding: 8px 9px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  background-color: rgb(4, 136, 4);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.2s ease;
  position: fixed;
  top: 15%;
  right: 40px; /* Position it in the top-right corner */
  z-index: 1000; /* Ensures it stays above other elements */
}

.download-button:hover {
  background-color: rgb(4, 136, 4);
  transform: translateY(-2px);
}

.download-button:active {
  background-color: rgb(4, 136, 4);
  transform: translateY(1px);
}

/* Responsive Design for Mobile Screens */
@media (max-width: 768px) {
  .order-details {
      padding: 15px;
  }

  .product-item {
      font-size: 14px;
      padding: 12px;
  }

  .product-image img {
      width: 80px;
      height: 80px;
  }

  .product-name {
      font-size: 16px;
  }

  .order-total {
      font-size: 16px;
      text-align: left;
  }

  /* Make the download button fixed on mobile view (top-right corner) */
  .download-button {
      font-size: 14px; /* Smaller font size */
      padding: 8px 16px; /* Smaller padding */
      width: auto; /* Adjust width to fit content */
  }
  .download-button {
    display: inline-block;
    padding: 8px 9px;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    background-color: rgb(4, 136, 4);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.3s ease, transform 0.2s ease;
    position: fixed;
    top: 18%;
    right: 20px; /* Position it in the top-right corner */
    z-index: 1000; /* Ensures it stays above other elements */
  }
}
