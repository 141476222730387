.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.contBtn{
  height: 40px;
  width: 100px;
  margin-left: auto;
  border-radius: 19px;
  border-color: #0d6efd;
}
.loginbtn{
  cursor: pointer;
  text-decoration: none;
  font-size: 14px;
}

.address-selected{
  color: white;
  background:#7fad39;
}
.img_hover{
  cursor: pointer;
}
.count_qty{
  height: 13px;
    width: 13px;
    background: #7fad39;
    font-size: 10px;
    color: #ffffff;
    line-height: 13px;
    text-align: center;
    font-weight: 700;
    display: inline-block;
    border-radius: 50%;
}
.img_size{
  height: 110px;
  width: 110px;
  margin-bottom: 10px;
}

/* order list */
/* @media (min-width: 1025px) {
.h-custom {
height: 100vh !important;
}
} */

.horizontal-timeline .items {
border-top: 2px solid #571515;
}

.horizontal-timeline .items .items-list {
position: relative;
margin-right: 0;
}

.horizontal-timeline .items .items-list:before {
content: "";
position: absolute;
height: 8px;
width: 8px;
border-radius: 50%;
background-color: #ddd;
top: 0;
margin-top: -5px;
}

.horizontal-timeline .items .items-list {
padding-top: 15px;
}


body {
  color: #000;
  overflow-x: hidden;
  height: 100%;
  /* background-color: #8C9EFF; */
  background-repeat: no-repeat;
}

.card {
  z-index: 0;
  background-color: #d4e6d4;
  padding-bottom: 20px;
  /* margin-top: 90px; */
  margin-bottom: 5px;
  border-radius: 10px;
}

.top {
  padding-top: 40px;
  padding-left: 13% !important;
  padding-right: 13% !important;
}

/*Icon progressbar*/
#progressbar {
  /* margin-bottom: 30px; */
  overflow: hidden;
  color: #455A64;
  padding-left: 0px;
  margin-top: 30px;
} 

#progressbar li {
  list-style-type: none;
  font-size: 13px;
  width: 20%;
  float: left;
  position: relative;
  font-weight: 400;
}

#progressbar .step0:before {
  font-family: FontAwesome;
  content: "\f10c";
  color: #fff;
}

#progressbar li:before {
  width: 20px;
  height: 19px;
  line-height: 18px;
  display: block;
  font-size: 10px;
  background: #C5CAE9;
  border-radius: 50%;
  margin: auto;
  padding: 0px;
}

/*ProgressBar connectors*/
#progressbar li:after {
  content: '';
  width: 100%;
  height: 3px;
  background: #C5CAE9;
  position: absolute;
  left: 0;
  top: 8px;
  z-index: -1;
}

#progressbar li:last-child:after {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  left: -50%;
}

/* #progressbar li:nth-child(2):after, #progressbar li:nth-child(3):after {
  left: -50%;
} */

#progressbar li:first-child:after {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  position: absolute;
  left: 50%;
}

#progressbar li:last-child:after {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

#progressbar li:first-child:after {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

/*Color number of the step and the connector before it*/
#progressbar li.active:before, #progressbar li.active:after {
  background: #651FFF;
}

#progressbar li.active:before {
  font-family: FontAwesome;
  content: "\f00c";
}

.icon {
  width: 14px;
  height: 14px;
  margin-top: 15px;
  /* margin-right: 5px; */
}

.icon-content { 
  padding-bottom: 20px;
}

@media screen and (max-width: 992px) {
  .icon-content {
      width: 50%;
  }
}

.col_row{
  padding-left: 6% !important;
  padding-right: 6% !important;
}

.img_size{
  height: 110px;
  width: 100px;
}

.pointer{
  cursor: pointer;
}

.progress-status-font{
  font-size: 12px ;
  line-height:15px
}

.selected-address{
 border: 1px solid;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 9999;
}

.spinning-image {
  width: 80px; /* Adjust size as needed */
  height: 80px;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}
