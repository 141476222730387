/* BottomNav.css */

/* Bottom Navigation Styles */
.bottom-nav {
    display: flex;
    justify-content: space-around;
    position: fixed;
    bottom: 0;
    width: 100%;
    background: rgb(4, 136, 4);
    padding: 10px 0;
    z-index: 100;
  }
  
  .nav-item {
    text-align: center;
    color: white;
    text-decoration: none;
  }
  
  .nav-item i {
    font-size: 24px;
  }
  
  .nav-item span {
    display: block;
    font-size: 12px;
  }
  
  /* Media Query for Mobile View */
  @media (max-width: 768px) {
    .bottom-nav {
      display: flex;
      justify-content: space-around;
    }
  }
  
  /* Hide Bottom Navigation on screens wider than 768px */
@media (min-width: 769px) {
    .bottom-nav {
      display: none;
    }
  }
  